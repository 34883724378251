import axios from "axios";

// helper type definitions

/**
 * @template T
 * @typedef {Object} Paginated
 * @prop {string|null} Paginated.next Url to next page
 * @prop {string|null} Paginated.prev Url to previous page
 * @prop {number} Paginated.pages Total number of pages
 * @prop {number} Paginated.page Current Page number
 * @prop {T[]} Paginated.data Paginated data
 */

// Create Axios instance with base URL and headers
export default axios.create({
  baseURL: "https://dashboard.supportletstalk.com/",
  // baseURL: "https://dashboard-test.supportletstalk.com/",
  // baseURL: "http://localhost:8080/",

  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
