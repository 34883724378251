import { initializeApp } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseApp = initializeApp({
    apiKey: "AIzaSyA9e058LgfaK9SkKMarjKfiEVVaUh1R5Uw",
    authDomain: "support-stress-free.firebaseapp.com",
    databaseURL: "https://support-stress-free-default-rtdb.firebaseio.com",
    projectId: "support-stress-free",
    storageBucket: "support-stress-free.appspot.com",
    messagingSenderId: "766096269533",
    appId: "1:766096269533:web:7a643adde4f234f4f0bb34",
    measurementId: "G-EX6HFSCGB1",
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.FIREBASE_DB_URL,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID,
//   measurementId: process.env.FIREBASE_MEASUREMENT_ID,
});
