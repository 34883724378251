import { createSlice } from '@reduxjs/toolkit'

export const pageNationSlice = createSlice({
    name : "page",
    initialState : {
        no : 1,
    },
    reducers : {
        increment : (state)=>{
            state.no = state.no + 1;
        },
        decrement : (state)=>{
            if(state.no - 1 !== 0){
                state.no = state.no - 1;
            }
        },
        reset : (state)=>{
            state.no = 1;
        }
    },
    

})

export const {increment,decrement, reset} = pageNationSlice.actions;
export default pageNationSlice.reducer;