import axios from "axios";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

// initialize firebase
import "../utils/firebase";

const initializeApp = () => {
  // Setting base URL for all API request via axios
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  dayjs.extend(dayjsPluginUTC);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
  } else {
    // Prod build code

    // Removing console.log from prod
    console.log = () => {};

    // init analytics here
  }
};

export default initializeApp;
