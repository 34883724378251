import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import { QueryClient, QueryClientProvider } from "react-query";
import 'react-toastify/dist/ReactToastify.css';

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const LoginPage = lazy(() => import("./pages/Login"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App() {
  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            {/* Place new routes over this */}
            <Route path="/app/*" element={<Layout />} />

            <Route
              path="*"
              element={
                <Navigate to={token ? "/app/new/home" : "/login"} replace />
              }
            />
          </Routes>
        </Router>
      </QueryClientProvider>
    </>
  );
}

export default App;
