import { configureStore } from "@reduxjs/toolkit";

//pageNO
import pageNationSlice from "../features/common/pageNationSlice";
import headerSlice from "../features/common/headerSlice";

const combinedReducer = {
  header: headerSlice,
  page: pageNationSlice,
};

export default configureStore({
  reducer: combinedReducer,
});
